.credits {
    background-color: #000000;
    height: 300px;
    width: 100%;
    padding: 48px;
    box-sizing: border-box;

    @media (min-width: 622px) {
        height: 322px;
        padding-left: 80px;
    }

    @media (min-width: 1200px) {
        height: 612px;
        padding: 160px 33px;
    }

    @media (min-width: 1620px) {
        height: 828px;
        padding: 160px 78px;
    }
}

.creditsList {
    list-style: none;
}

.creditsText {
    color: #ffffff;
    font-size: 12px;
    line-height: 150%;

    @media (min-width: 1200px) {
        font-size: 14px;
    }

    @media (min-width: 1620px) {
        font-size: 16px;
    }
}

.creditsRole {
    color: rgba(255, 255, 255, 0.50);
}

.creditsSpecial {
    margin-top: 20px;
}