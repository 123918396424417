.mainTitle{
    font-family: Inter;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 36px;
    font-size: 46px;

    @media (min-width: 622px) {
        margin-bottom: 28px;
        font-size: 68px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        max-width: 720px;
    }
}

.title{
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: 622px) {
        font-weight: 500;
    }
}

.contacts{
    list-style: none;
    
    @media (min-width: 1200px) {
        max-width: 758px;
    }
}

.email{
    color: #000;
    font-size: 14px;
    font-weight: 700;
    text-decoration-line: underline;

    @media (min-width: 622px) {
        font-weight: 500;
    }
}

.text{
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    
    
    @media (min-width: 1200px) {
        width: 365px;
    }
}

.infoSection{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 768px) {
        margin-top: 80px;
    }

    @media (min-width: 1200px) {
        padding-left: 44px;
        flex-direction: row;
    }
    
    @media (min-width: 1620px) {
        padding-left: 124px;
    }
}

.photo{
    display: block;
    width: 224px;
    height: 338px;
    margin-left: auto;
    margin-top: 36px;
        
    @media (min-width: 622px) {
        width: 324px;
        height: 487px;
        margin-top: 48px;
    }

    @media (min-width: 768px) {
        margin-top: 24px;
    }

    @media (min-width: 1200px) {
        margin-top: 0;
    }
    
    @media (min-width: 1620px) {
        width: 566px;
        height: 850px;
    }
}

.infoBox{
    padding: 36px 16px 0;

    @media (min-width: 622px) {
        padding: 48px 24px 0;
    }
    
    @media (min-width: 768px) {
        padding: 24px 24px 0;
    }
    
    @media (min-width: 1200px) {
        padding: 48px 0;
    }
}

.titleBox {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    justify-content: space-between;


    @media (min-width: 622px) {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    @media (min-width: 1200px) {
        max-width: 758px;
    }

}

.contacts {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;

    @media (min-width: 622px) {
        margin-bottom: 28px;
    }
}

.textBox{
    display: flex;
    flex-direction: column;

    @media (min-width: 622px) {
        flex-direction: row;
    }
}

.column{
    margin-bottom: 40px;

    @media (min-width: 622px) {
        margin-right: 28px;
        margin-bottom: 0;
        width: calc(50% - 14px);
        box-sizing: border-box;
    }
}

.column:last-child {
    margin-bottom: 0;

    @media (min-width: 622px) {
        margin-right: 0;
    }
}

.text:nth-child(odd){
    margin-bottom: 28px;
}

