.video{
    width: 100%;
    display: block;
    object-fit: cover;
    height: 400px;

    @media (min-width: 622px) {
        height: 442px;
    }
    
    @media (min-width: 1200px) {
        height: 613px;
    }
    
    @media (min-width: 1620px) {
        height: 828px;
    }
}

.videoSection{
    position: relative;
}

.unmuteButton{
    z-index: 999;
    position: absolute;
    left: 10px;
    top: 100px;
    cursor: pointer;

    background: #00000052;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 12px 0;

    @media (min-width: 420px) {
        top: 85px;
    }
        
    @media (min-width: 520px) {
        top: 60px;
    }

    @media (min-width: 622px) {
        left: 20px;
        top: 50px;
    }

    @media (min-width: 740px) {
        top: 35px;
    }

    @media (min-width: 800px) {
        left: 40px;
        top: 35px;
    }

    @media (min-width: 865px) {
        left: 60px;
    }

    @media (min-width: 900px) {
        left: 85px;
    }

    @media (min-width: 950px) {
        left: 120px;
    }

    @media (min-width: 1000px) {
        left: 140px;
    }

    @media (min-width: 1050px) {
        left: 160px;
    }

    @media (min-width: 1100px) {
        left: 180px;
    }

    @media (min-width: 1150px) {
        left: 220px;
    }
    
    @media (min-width: 1200px) {
        left: calc(50% - 530px);
        top: 20px;
    }

    @media (min-width: 1600px) {
        left: calc(50% - 720px);
        top: 20px;
    }
}

.invisibleButton{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
    opacity: 0%;
    width: 100%;
    height: 100%;
}

.invisibleButton:active {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.svg{
    margin-right: 10px;
}