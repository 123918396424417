@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.video{
    width: 100%;
    display: block;

    @media (min-width: 768px) {
        height: 100vh;
        object-fit: cover;
    }

}

.videoSection{
    position: relative;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 22px;
    height: 100vh;

    @media (min-width: 622px) {
        padding-top: 40px;
    }

    @media (min-width: 768px) {
        padding-top: 0;
    }



}

.arrow{
    position: absolute;
    z-index: 999;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 32px;
    height: 32px;

    @media (min-width: 768px) {
        width: 48px;
        height: 48px;
    }
}

.title{
    color: #1D1D1D;
    font-family: Inter;
    font-size: 97.8px;
    font-weight: 500;
    line-height: 86.023%;
    text-transform: uppercase;
    width: 363px;
    margin-top: 21px;
    padding-bottom: 72px;
}

.bold{
    font-weight: 900;
}

.italic{
    font-style: italic;
}

.loader{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
}

.loaderText{
    max-width: 218px;
    color: #A0A0A0;
    font-size: 34px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0;
    animation: fadeIn 3s linear forwards;
}

.hidden{
    display: none;
}