.footerBox{
    background-color:#000;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (min-width: 768px) {
        height: 125px;
    }
}

.footerText{
    color: #FBF6F4;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    
    @media (min-width: 768px) {
        font-size: 24px;
    }
}

.footer{
    margin-top: 80px;
}