.carrousel{
    position: relative;
    background-color: #000000;
}

.contentList{
    display: flex;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    cursor: ew-resize;   
}

.contentList::-webkit-scrollbar {
    display: none;
}

.title{
    color: #FFF;
    text-shadow: 0px 0px 4px #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: 622px) {
        font-size: 16px;
        font-weight: 700;
    }

    @media (min-width: 1200px) {
        font-size: 22px;
    }

    @media (min-width: 1620px) {
        font-size: 24px;
    }
}

.titleBox{
    position: absolute;
    left: 18px;
    bottom: 22px;

    @media (min-width: 622px) {
        bottom: 12px;
    }

    @media (min-width: 1200px) {
        left: 25px;
        bottom: 26px;
    }

    @media (min-width: 1620px) {
        left: 34px;
        bottom: 43px;
    }
}

.photo{
    height: 400px;

    @media (min-width: 622px) {
        height: 442px;
    }
    
    @media (min-width: 1200px) {
        height: 612px;
    }
    
    @media (min-width: 1620px) {
        height: 828px;
    }
}

.credits{
    background-color: #000000;
    height: 400px;
    width: 346px;
    padding: 78px 14px;
    box-sizing: border-box;
    
        @media (min-width: 622px) {
            height: 442px;
            width: 346px;
        }
    
        @media (min-width: 1200px) {
            height: 612px;
            width: 486px;
            padding: 160px 33px;
        }
    
        @media (min-width: 1620px) {
            height: 828px;
            width: 621px;
            padding: 160px 78px;
        }
}

.creditsList{
    list-style: none;
}

.creditsText{
    color: #ffffff;
    font-size: 12px;
    line-height: 150%;
    
    @media (min-width: 1200px) {
        font-size: 14px;
    }
    
    @media (min-width: 1620px) {
        font-size: 16px;
    }
}

.creditsRole{
    color: rgba(255, 255, 255, 0.50);
}

.creditsSpecial{
    margin-top: 20px;
}

.creditsFirstRow {
    margin-bottom: 20px;
}

.video{
    display: block;
    height: 400px;
    
    @media (min-width: 622px) {
        height: 442px;
    }
    
    @media (min-width: 1200px) {
        height: 612px;
    }
    
    @media (min-width: 1620px) {
        height: 828px;
    }
}